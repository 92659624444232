import { Injectable, inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  distinctUntilChanged, map, tap
} from 'rxjs/operators';
import { AppState, AppStateKey } from '~core/states/app/app.state';
import { NetworkState } from '~offline/states/network-state/network.state';
import { User } from '~shared/models/user.model';
import { SafeTKDB } from '~indexedDB';
import { includes } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  private indexedDB = inject(SafeTKDB);
  private networkState = inject(NetworkState);
  private state = inject(AppState);

  initAppState(): Observable<User> {
    /* Sets facilityId in app state when facility changes */
    this.state.facility$
      .pipe(
        tap(({ name }) => this.state.set('facilityName', name)),
        map(({ ID }) => ID),
        distinctUntilChanged()
      )
      .subscribe(ID => {
        this.state.set('facilityId', +ID);
        localStorage.setItem('lastFacilityId', ID);
      });

    return of(this.state.get('user'));
  }

  async loadsessionStorage(): Promise<void> {
    const appStateKeys = [
      'childPageTitle',
      'connectionStringName',
      'facility',
      'facilityId',
      'facilityName',
      'pageTitle',
      'user',
      'loggedInViaSSO',
      'showTopbar'
    ];

    for (let key of appStateKeys) {
      let value = sessionStorage.getItem(key);


      if (!value && this.indexedDB?.db?.appState) {
        const cachedItem: any = await this.indexedDB.db.appState.get(key);
        value = cachedItem?.value;
      }

      try {
        if (!includes(stringFieldsExcludedFromParsingErrorLog, key)) {
          value = JSON.parse(value);
        }

      } catch (err) {
        console.error('Error parsing sessionStorage value for', key, value);
      }

      if (value) {
        this.state.set(key as AppStateKey, value);
      }
    }
  }
}

export function InitializeAppState(appSvc: AppService): () => void {
  return () => appSvc.loadsessionStorage();
}

const stringFieldsExcludedFromParsingErrorLog = [
  'connectionStringName',
  'facilityName',
  'pageTitle'
];
