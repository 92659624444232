import {
  Component, inject, OnInit 
} from '@angular/core';
import { Router } from '@angular/router';
import { FacilityState } from '~core/states/facility/facility.state';

@Component({
  template: ''
})
export class DefaultPageComponent implements OnInit {

  private facilityState = inject(FacilityState);
  private router = inject(Router);

  ngOnInit(): void {
    if (this.facilityState.get('moduleSettings')?.CustomDashboards) {
      this.router.navigate([ '/dashboards' ]);
    } else {
      this.router.navigate([ '/announcements' ]);
    }

  }

}
