import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { isNull } from 'lodash';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { AuthState } from '~auth/states/auth.state';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard  {

  constructor(
    private authState: AuthState,
    private router: Router
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.authState.isLoggedIn$
      .pipe(
        first(isLoggedIn => !isNull(isLoggedIn)),
        map(isLoggedIn => {
          if (isLoggedIn) {
            return this.router.createUrlTree([ '/' ]);
          }

          return true;
        })
      );
  }

}
