import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { filter, switchMap } from 'rxjs/operators';
import { ConfirmService } from '~confirmation/service/confirm.service';

@Injectable({
  providedIn: 'root'
})
export class PromptUpdateService {

  constructor(
    confirmSvc: ConfirmService,
    updates: SwUpdate
  ) {
    updates.versionUpdates
      .pipe(
        filter(ev => ev.type === 'VERSION_READY'),
        switchMap(() => confirmSvc.confirm('', 'Update Available', {
          message: 'A new version is available, please refresh.',
          showCancel: false,
          confirmText: 'Refresh'
        }))
      )
      .subscribe(confirmed => {
        updates.activateUpdate().then(() => document.location.reload());
      });
  }
}
