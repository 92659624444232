import {
  Component, DestroyRef, ViewEncapsulation 
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from 'src/environments/environment';
import { AppState } from '~core/states/app/app.state';
import { NetworkState } from '~offline/states/network-state/network.state';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {

  appInsights: ApplicationInsights;

  appInsightsLoaded = false;

  loading$ = this.appState.loading$;

  constructor(
    private appState: AppState,
    private destroyRef: DestroyRef,
    private networkState: NetworkState,
    private router: Router
  ) {
    if (environment?.APP_INSIGHTS_KEY?.length) {
      this.initAppInsights();
    }
  }

  private initAppInsights() {
    this.networkState.isOnline$
      .pipe(
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(isOnline => {
        if (isOnline && !this.appInsightsLoaded) {
          const angularPlugin = new AngularPlugin();

          this.appInsights = new ApplicationInsights({
            config: {
              instrumentationKey: environment.APP_INSIGHTS_KEY,
              extensions: [ angularPlugin ],
              extensionConfig: {
                [angularPlugin.identifier]: { router: this.router }
              }
            }
          });

          this.appInsights.loadAppInsights();
          this.appInsightsLoaded = true;
          console.debug('Application Insights enabled & tracking');
        } else {
          this.appInsights.unload();
          this.appInsightsLoaded = false;
          console.debug('Application Insights disabled by network status');
        }
      });
  }
}
