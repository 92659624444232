<div class="flex flex-col flex-nowrap justify-start items-stretch h-full w-full overflow-y-auto mat-elevation-z2">
  <div class="sticky top-0 w-full bg-[rgb(239,239,239)] z-20">
    <div class="flex flex-row flex-nowrap justify-between items-center p-4 w-full h-16">
      <p class="text-2xl font-semibold leading-6 text-stilRed-200">Notifications</p>
      <div class="flex gap-4">
        @if (notifications?.unread?.length) {
          <button
            mat-stroked-button
            class="!min-w-[48px] !w-12"
            color="warn"
            (click)="markAllRead()"
            tp="Mark All Notifications Read"
          >
            <mat-icon class="!m-0">mark_email_read</mat-icon>
          </button>
        }
        @if (notificationStatus.value === 'all' && (notifications?.unread?.length || notifications?.read?.length)) {
          <button
            mat-stroked-button
            class="!min-w-[48px] !w-12"
            color="warn"
            (click)="deleteAll()"
            tp="Delete All Notifications"
          >
            <mat-icon class="!m-0">delete</mat-icon>
          </button>
        }
      </div>
    </div>

    <div class="flex flex-nowrap justify-center items-center w-full pb-4">
      <mat-button-toggle-group
        name="showReadNotifications"
        appearance="legacy"
        aria-label="Show Read Notifications"
        #notificationStatus="matButtonToggleGroup"
        class="!border-4 !shadow-none border-gray-300 bg-gray-300 !rounded-md"
        value="unread"
      >
        <mat-button-toggle
          value="unread"
          class="rounded-md"
          #unread="matButtonToggle"
          [ngClass]="{ '!bg-white !text-stilRed-200': unread.checked }"
        >
          Unread
        </mat-button-toggle>
        <mat-button-toggle
          value="all"
          class="rounded-md"
          #all="matButtonToggle"
          [ngClass]="{ '!bg-white !text-stilRed-200': all.checked }"
        >
          All
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>

  <div
    class="p-4 pt-0"
    [class.pr-[22px]]="notificationStatus.value === 'unread'"
  >
    @for (notification of notifications.unread; track notification.ID; let i = $index) {
      <app-notification
        [notification]="notification"
        (update)="syncNotifications()"
      ></app-notification>
    } @empty {
      @if (notificationStatus.value === 'unread' || notifications?.read?.length) {
        <h5 class="text-center text-semibold text-gray-600 bg-gray-200 p-8 w-full">
          You have no unread notifications!
        </h5>
      }
    }

    @if (notificationStatus.value === 'all') {
      @if (notifications?.read?.length || notifications?.unread?.length) {
        <div class="pt-8 px-4 text-lg font-medium leading-6">Read Notifications</div>
      }
      @for (notification of notifications.read; track notification.ID; let i = $index) {
        <app-notification
          [notification]="notification"
          (update)="syncNotifications()"
        ></app-notification>
      } @empty {
        <h5 class="text-center text-semibold text-gray-600 bg-gray-200 p-8 w-full">
          You have no {{ notifications?.unread?.length ? 'read ' : '' }}notifications!
        </h5>
      }
    }
  </div>
</div>
