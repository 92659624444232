import {
  HttpHandler, HttpInterceptor, HttpRequest 
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthState } from '~auth/states/auth.state';

@Injectable({
  providedIn: 'root'
})
export class AuthTokenInterceptor implements HttpInterceptor {

  constructor(
    private authState: AuthState
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const token = this.authState.get('token');

    if (token) {
      req = this.addAuthToken(req, token);
    }

    return next.handle(req);
  }

  private addAuthToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    return req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

}
