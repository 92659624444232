import {
  Component, DestroyRef, OnInit, inject
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { SLIDE_IN_OUT_ANIMATION } from '~animations/slide-in-out.animation';
import { InactivityService } from '~auth/services/inactivity/inactivity.service';

import { LayoutService } from '../../services/layout/layout.service';
import { AppState } from '../../states/app/app.state';
import { SidebarState } from '../../states/sidebar/sidebar.state';
import { NotificationsService } from 'src/app/notifications/service/notifications.service';
import { showHideHorizontal } from '~animations/show-hide-horizontal.animation';
import { FacilitySettingService } from '~core/services/facility-setting/facility-setting.service';
import { AuthState } from '~auth/states/auth.state';
import { environment } from 'src/environments/environment';
import keyBy from 'lodash/keyBy';
import { FacilityState } from '~core/states/facility/facility.state';
import { LocalStorage } from '~core/services/local-storage';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: [ './layout.component.scss' ],
  animations: [ SLIDE_IN_OUT_ANIMATION, showHideHorizontal() ]
})
export class LayoutComponent implements OnInit {

  private destroyRef = inject(DestroyRef);
  private facilitySettingSvc = inject(FacilitySettingService);
  private localStorage = inject(LocalStorage);

  collapseRightNav: boolean;

  collapsedState$ = this.sidebarState.collapsed$
    .pipe(map(collapsed => (collapsed ? 'hide' : 'show')));

  isMobile: boolean;

  navigationEnd$ = this.router.events.pipe(filter(ev => ev instanceof NavigationEnd));

  showTopbar$ = this.appState.showTopbar$;

  showNotifications = this.notificationsSvc.showNotifications;

  constructor(
    public appState: AppState,
    private authState: AuthState,
    private facilityState: FacilityState,
    private inactivitySvc: InactivityService,
    private layoutService: LayoutService,
    private notificationsSvc: NotificationsService,
    private sidebarState: SidebarState,
    private router: Router,
    private titleSvc: Title
  ) { }

  ngOnInit() {
    this.localStorage.removeItem('redirect');

    if (typeof Worker !== 'undefined') {
      const worker = new Worker(new URL('../../../data-sync.worker', import.meta.url));

      worker.onmessage = ({ data }) => {
        this.facilityState.set('prefixes', keyBy(data.prefixes, 'permitType'));
      };

      worker.postMessage({
        apiUrl: environment.API_URL,
        connectionStringName: this.appState.get('connectionStringName'),
        facilityID: this.appState.get('facilityId'),
        token: this.authState.get('token')
      });
    } else {
      console.warn('Web Workers are not supported! Data sync will run on main thread!');
      this.facilitySettingSvc.syncOfflineData();
    }

    this.inactivitySvc.startWatch();

    this.appState.user$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(user => {
        let title = 'SafeTK';
        if (user?.userName) {
          title += ` - ${user.userName}`;
        }
        this.titleSvc.setTitle(title);
      });

    this.layoutService.isMobile$.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(isMobile => {
      this.isMobile = isMobile;
      this.sidebarState.set('collapsed', isMobile);
      this.sidebarState.set('rightNavCollapsed', isMobile);
    });

    this.sidebarState.rightNavCollapsed$.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((isRightCollapsed: boolean) => {
      this.collapseRightNav = isRightCollapsed;
    });

    this.navigationEnd$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(() => this.isMobile)
      ).subscribe(() => this.sidebarState.set('collapsed', true));
  }
}
