import {
  HttpEvent, HttpHandler, HttpInterceptor, HttpRequest 
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiUrlInterceptorService implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url = environment.API_URL;
    const fullURL = url + req.url;

    req = req.clone({
      url: fullURL,
      withCredentials: true
    });
    return next.handle(req);
  }
}
