import { HttpClient } from '@angular/common/http';
import {
  Inject, inject, Injectable
} from '@angular/core';
import { WINDOW } from '~core/services/window/window.service';
import { AppState } from '~core/states/app/app.state';
import { NetworkState } from '~offline/states/network-state/network.state';

import { CheckForUpdateService } from '../service-worker/check-for-update/check-for-update.service';
import {
  HandleUnrecoverableStateService
} from '../service-worker/handle-unrecoverable-state/handle-unrecoverable-state.service';
import { LogUpdateService } from '../service-worker/log-update/log-update.service';
import { PromptUpdateService } from '../service-worker/prompt-update/prompt-update.service';

@Injectable({
  providedIn: 'root'
})
export class OfflineService {

  private appState = inject(AppState);
  private http = inject(HttpClient);

  constructor(
    @Inject(WINDOW) private window: Window,
    private networkState: NetworkState,
    private checkForUpdateSvc: CheckForUpdateService,
    private logUpdateSvc: LogUpdateService,
    private promptUpdateSvc: PromptUpdateService,
    private handleUnrecoverableStateSvc: HandleUnrecoverableStateService
  ) {}

  async init() {
    this.networkState.set('networkOnline', (window?.navigator?.onLine));
    this.window.addEventListener('online', () => this.networkState.set('networkOnline', true));
    this.window.addEventListener('offline', () => this.networkState.set('networkOnline', false));
  }
}

export function initializeOfflineService() {
  inject(OfflineService).init();
}
