<div
  id="stil-sidebar"
  class="flex flex-col justify-start items-stretch w-full h-full"
  [class.collapsed]="visibility === 'hide'"
>
  <div
    [routerLink]="dashboardRoute"
    class="nav-block p-2 flex flex-row justify-start items-center w-full"
    routerLinkActive="active-link"
    [class.selected]="dashboardsActive(dashboardRoute)"
  >
    <i class="fas fa-home w-[25px]"></i>
    <span class="nav-block-title p-1 grow">
      {{ 'General.LeftNav.dashboard' | translate }}
    </span>
  </div>

  @for (group of navGroups$ | async; track group.ID) {
    <app-nav-group
      [group]="group"
      [isOpen]="openGroupID() === group.ID"
      [isActive]="isActive(group)"
      (groupClick)="setOpenGroup(group.ID)"
    ></app-nav-group>
  }
</div>
