import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { HotToastService } from '@ngxpert/hot-toast';
import { find } from 'lodash';
import { AuthState } from '~auth/states/auth.state';
import { WINDOW } from '~core/services/window/window.service';
import { AppState } from '~core/states/app/app.state';
import { SAMLDataService } from '~shared/services/apiSAMLController';
import { STILFacilityDataService } from '~shared/services/apiSTILFacilityController';
import { STILGlobalDataDataService } from '~shared/services/apiSTILGlobalDataController';

export interface LoginPageData {
  connectionStringNames: string[];
  defaultConnectionStringName: string;
  facilities: any[];
  defaultFacility: any[];
  idpSettings: any;
}

@Injectable({
  providedIn: 'root'
})
export class LoginPageResolve  {

  constructor(
    private appState: AppState,
    private state: AuthState,
    private stilGlobalDataSvc: STILGlobalDataDataService,
    private facilityDataSvc: STILFacilityDataService,
    private samlDataService: SAMLDataService,
    private toast: HotToastService,
    @Inject(WINDOW) private window: Window
  ) { }

  async resolve(route: ActivatedRouteSnapshot): Promise<LoginPageData> {

    const idpSettings = await this.samlDataService.getSettings().toPromise();

    if (idpSettings.stilUseSSO && (route.queryParams?.flag === "SSOSafeTKPro") && !route.queryParams?.Token?.length && !this.state.get('loggedOut')) {
      this.window.location.href = idpSettings.spAPIRedirect + "?flag=" + route.queryParams.flag;
      return;
    }

    if (idpSettings.useSSO && (route.queryParams?.flag !== "SafeTKPro") && !route.queryParams?.Token?.length && !this.state.get('loggedOut')) {
      if (route.queryParams?.flag?.length) {
        this.window.location.href = idpSettings.spAPIRedirect + "?flag=" + route.queryParams.flag;
        return;
      } else {
        this.window.location.href = idpSettings.spAPIRedirect;
      }
    }

    this.appState.set('loading', true);
    /* Get connection strings */

    let connectionStringNames = await this.stilGlobalDataSvc.getConnectionStringList().toPromise();


    if ((idpSettings.useSSO || idpSettings.stilUseSSO) && (route.queryParams?.flag !== "SafeTKPro") && route.queryParams?.Token?.length) {
      const SSOConnectionStrings = [];

      for (const conn of connectionStringNames) {
        try {
          const facilityList = await this.facilityDataSvc.getActiveFacilitiesForSSOUser(route.queryParams.Token, conn).toPromise();

          if (facilityList.length) {
            SSOConnectionStrings.push(conn);
          }
        } catch (err) {
          console.debug(`No facilities for ${conn}`);
        }
      }

      connectionStringNames = SSOConnectionStrings;
    }

    if (!connectionStringNames.length) {
      this.toast.error('No ConnectionString names were found. There must be an issue.');
    }

    const lastConnectionStringName = localStorage.getItem('lastConnectionStringName');

    const last = find(connectionStringNames, (str) => str === lastConnectionStringName);
    const defaultConnectionStringName = last ?? connectionStringNames[0];

    /* Get facilities */
    let facilities = [];

    if (defaultConnectionStringName) {
      if ((idpSettings.useSSO || idpSettings.stilUseSSO) && (route.queryParams?.flag !== "SafeTKPro") && route.queryParams?.Token?.length) {
        facilities = await this.facilityDataSvc.getActiveFacilitiesForSSOUser(route.queryParams.Token, defaultConnectionStringName).toPromise();
      } else {
        facilities = await this.facilityDataSvc.getRecords(true, defaultConnectionStringName).toPromise();
      }

      if (!facilities.length) {
        this.toast.error('No Facilities were found for the default connection string name. Please try another connection string');

      }
    }

    const facilityId = sessionStorage.getItem('facilityId') || localStorage.getItem('lastFacilityId');

    const defaultFacility = find(facilities, { ID: +facilityId }) ?? facilities[0];

    this.appState.set('loading', false);


    if (!defaultConnectionStringName && (idpSettings.useSSO || idpSettings.stilUseSSO)) {
      this.state.set('loggedOut', true);
      this.appState.set('loggedInViaSSO', true);
    }

    return ({
      connectionStringNames,
      defaultConnectionStringName,
      defaultFacility,
      facilities,
      idpSettings
    });
  }
}
