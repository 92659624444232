import { Injectable, inject } from '@angular/core';
import {
  Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router
} from '@angular/router';
import { filter } from 'rxjs/operators';
import { AppState } from '~core/states/app/app.state';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private appState = inject(AppState);
  private router = inject(Router);

  init(): void {
    this.router.events
      .pipe(
        filter((event: Event) => event instanceof NavigationStart)
      )
      .subscribe(() => this.appState.set('loading', true));

    this.router.events
      .pipe(
        filter((event: Event) => (
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel ||
          event instanceof NavigationError
        ))
      )
      .subscribe(() => this.appState.set('loading', false));
  }
}

export const initializeLoading = (loadingSvc: LoadingService) => () => loadingSvc.init();
