import { inject, Injectable } from '@angular/core';
import {
  Route, Router, UrlSegment, UrlTree 
} from '@angular/router';
import { FacilityState } from '~core/states/facility/facility.state';

@Injectable({
  providedIn: 'root'
})
export class DashboardGuard  {

  private router = inject(Router);
  private facilityState = inject(FacilityState);

  canLoad(
    route: Route,
    segments: UrlSegment[]): boolean | UrlTree {

    const canLoad = !!(this.facilityState.get('moduleSettings')?.CustomDashboards);
    return canLoad ? true : this.router.createUrlTree([ '/' ]);
  }
}
