import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { connectToSignalR, SignalrService } from './services/signalr/signalr.service';


@NgModule({
  declarations: [],
  imports: [ CommonModule ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: connectToSignalR,
      deps: [ SignalrService ],
      multi: true
    }
  ]
})
export class SignalrModule { }
