<div class="stil-header pl-2 flex flex-row w-full">
  <div class="flex flex-row flex-nowrap justify-start items-center w-full">
    <div
      (click)="toggleSidebar()"
      class="p-2"
    >
      <i
        [@flipAround]="(collapsed$ | async) ? 'right' : 'left'"
        class="collapse-btn fas fa-angle-double-right"
        aria-hidden="false"
        aria-label="Toggle Menu"
      ></i>
    </div>

    <div
      id="facility-title"
      class="flex flex-row no-wrap justify-between items-center mr-4"
    >
      <span
        class="app-title lead mr-2"
        routerLink="/"
      >
        SafeTK
        <sup>®</sup>
      </span>

      <span class="plant-title">
        {{ facilityName$ | async | ellipsis: 25 }}
      </span>
    </div>

    <div class="flex flex-row flex-nowrap justify-start items-center w-full">
      @if (activePermitType$ | async; as permitType) {
        <i
          [class]="permitType | appPermitTypeIcon"
          [tp]="[permitType, 'LEFT_NAV', permitType] | translate"
          tpPlacement="bottom"
        ></i>
      }

      @if (pageTitle$ | async; as pageTitle) {
        <mat-icon class="mx-1">chevron_right</mat-icon>
        {{ pageTitle }}
      }

      @if (childPageTitle$ | async; as childPageTitle) {
        <mat-icon class="mx-1">chevron_right</mat-icon>
        {{ childPageTitle }}
      }
    </div>

    @if (isOffline$ | async) {
      <div class="p-1 h-full">
        <div
          class="flex flex-row flex-nowrap justify-center items-center h-full border border-yellow-400 bg-yellow-600 w-96"
        >
          <h5 class="font-bold">OFFLINE</h5>
        </div>
      </div>
    }

    @if (offlineModeEnabled && (networkOnline$ | async)) {
      <button
        mat-icon-button
        (click)="toggleOfflineMode()"
        [tp]="(isOffline$ | async) ? 'Go Online' : 'Go Offline'"
      >
        <mat-icon>{{ (isOffline$ | async) ? 'wifi_off' : 'wifi' }}</mat-icon>
      </button>
    }

    @if (notificationsEnabled) {
      <app-notifications-icon></app-notifications-icon>
    }

    <app-user-menu-icon></app-user-menu-icon>

    @if (serverConnectionStatusIconEnabled) {
      <div class="btn-group mr-2">
        <button
          (click)="broadcastButton($serverConnectionStatus(), $event)"
          class="bg-transparent p-0 border-0"
          [tp]="'Server status ' + $serverConnectionStatus()"
        >
          <span
            id="icon"
            class="trigger"
          >
            <i
              class="fas fa-server"
              [style.color]="$serverConnectionStatus() | serverConnectionStatus"
              aria-hidden="true"
            ></i>
          </span>
        </button>
      </div>
    }

    <div [ngClass]="serverConnectionStatusIconEnabled ? 'w-[5px]' : 'w-[20px]'"></div>
  </div>
</div>
