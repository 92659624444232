import { ENVIRONMENT_INITIALIZER, NgModule } from '@angular/core';

import { initializeOfflineService } from './services/offline/offline.service';



@NgModule({
  providers: [
    {
      provide: ENVIRONMENT_INITIALIZER,
      useValue: initializeOfflineService,
      multi: true
    }
  ]
})
export class OfflineModule { }
