<div class="flex flex-col w-full h-full justify-start items-center">
  <div class="flex flex-row h-[25%] justify-center items-center">
    <div class="flex flex-col w-[10%] justify-center items-center">
      <img
        src="./assets/SafeTK-DragonSlayer-Logo.png"
        class="img img-fluid"
      />
    </div>
  </div>

  <div class="border border-stilRed-200 bg-gray-100 rounded p-5 flex flex-row h-[450px] justify-center items-center">
    <form
      [formGroup]="loginForm"
      class="w-[300px]"
      [ngClass]="{ 'animated shake': showInvalidCredsMsg }"
    >
      @if (showInvalidCredsMsg && !loggedInViaSSO) {
        <div class="text-red-600 mb-1">
          <p>Invalid Credentials. Please try again.</p>
        </div>
      }

      @if (loggedInViaSSO && (showInvalidCredsMsg || loggedOut)) {
        <div class="text-red-600 mb-1">
          @if (showInvalidCredsMsg) {
            <p>
              Invalid Credentials.
              <br />
              Login again or Start Over.
            </p>
          }

          @if (loggedOut) {
            <p>You have been logged out.</p>
          }

          <button
            [disabled]="loading"
            mat-flat-button
            (click)="startOver()"
            color="primary"
            class="py-4 w-full !bg-stilRed-200"
          >
            @if (loading) {
              <span class="spinner-border spinner-border-sm mr-1"></span>
            }
            Start Over
          </button>
        </div>
      }

      @if (connectionStringNames?.length > 1 && !(loggedInViaSSO && loggedOut)) {
        <div class="mb-2">
          <label
            class="py-4 text-base w-full"
            [class.is-invalid]="submitted && connectionStringNameCtrl.errors"
            for="connectionStringName"
          >
            Connection String Name
          </label>
          <select
            formControlName="connectionStringName"
            placeholder="Loading..."
            class="border p-2 w-full rounded"
            [class.is-invalid]="submitted && connectionStringNameCtrl.errors"
          >
            @for (conn of connectionStringNames; track $index) {
              <option [value]="conn">
                {{ conn }}
              </option>
            }
          </select>

          @if (!connectionStringNames?.length) {
            <mat-progress-bar
              color="primary"
              mode="indeterminate"
            ></mat-progress-bar>
          }

          @if (submitted && connectionStringNameCtrl.errors?.required) {
            <div class="invalid-feedback">Connection String Name is required</div>
          }
        </div>
      }

      @if (facilities?.length > 1 && !(loggedInViaSSO && loggedOut)) {
        <div class="mb-2">
          <label
            class="py-4 text-base w-full"
            [class.is-invalid]="submitted && facilityIdCtrl.errors"
            for="facilityId"
          >
            Facility
          </label>
          <select
            formControlName="facilityId"
            placeholder="Loading..."
            class="border border-gray-600 p-2 w-full rounded"
            [class.is-invalid]="submitted && facilityIdCtrl.errors"
          >
            @for (facility of facilities; track facility.ID) {
              <option [value]="facility.ID">
                {{ facility.name }}
              </option>
            }
          </select>

          @if (submitted && facilityIdCtrl.errors?.required) {
            <div class="invalid-feedback">Facility is required</div>
          }
        </div>
      }

      @if (!(useSSO || stilLoggingIn || loggedInViaSSO)) {
        <div class="mb-2">
          <label
            class="py-4 text-base w-full"
            [class.is-invalid]="submitted && usernameCtrl.errors"
            for="username"
          >
            Username
          </label>
          <input
            type="text"
            formControlName="username"
            class="border border-gray-600 p-2 w-full rounded"
            [ngClass]="{ 'is-invalid': submitted && usernameCtrl.errors }"
          />

          @if (submitted && usernameCtrl?.errors?.required) {
            <div class="invalid-feedback">Username is required</div>
          }
        </div>
      }

      @if (!(useSSO || stilLoggingIn || loggedInViaSSO)) {
        <div class="mb-2">
          <label
            class="py-4 text-base w-full"
            for="password"
            [class.is-invalid]="submitted && passwordCtrl.errors"
          >
            Password
          </label>
          <input
            type="password"
            formControlName="password"
            class="border border-gray-600 p-2 w-full rounded"
            [class.is-invalid]="submitted && passwordCtrl.errors"
          />

          @if (submitted && passwordCtrl.errors.required) {
            <div class="invalid-feedback">Password is required</div>
          }
        </div>
      }

      @if (!(loggedInViaSSO && loggedOut)) {
        <div class="mt-2">
          <button
            [disabled]="loading || (loginForm.touched && loginForm.invalid)"
            (click)="onSubmit()"
            mat-flat-button
            color="primary"
            class="py-4 w-full !bg-stilRed-200"
          >
            @if (loading) {
              <span class="spinner-border spinner-border-sm mr-1"></span>
            }

            Login
          </button>
        </div>
      }
    </form>
  </div>

  <div id="version">Build Version: {{ uiVersion }}</div>
</div>
