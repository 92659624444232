import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  inject
} from '@angular/core';
import { NotificationsService } from '../../service/notifications.service';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TippyDirective } from '@ngneat/helipopper';
import { PermitTypeIconPipe } from '~shared/pipes/permit-type-icon.pipe';
import { TranslatePipeModule } from '~shared/pipes/translate.pipe';
import groupBy from 'lodash/groupBy';
import { TimeAgoPipe } from '~date/time-ago-pipe/time-ago.pipe';
import { MatDividerModule } from '@angular/material/divider';
import { NotificationComponent } from '../notification/notification.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import orderBy from 'lodash/orderBy';
import { ConfirmService } from '~confirmation/service/confirm.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-notifications-panel',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatDividerModule,
    MatIconModule,
    NotificationComponent,
    PermitTypeIconPipe,
    TimeAgoPipe,
    TippyDirective,
    TranslatePipeModule
  ],
  templateUrl: './notifications-panel.component.html',
  styleUrls: [ './notifications-panel.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsPanelComponent implements OnInit, AfterViewInit, OnDestroy {

  private cdr = inject(ChangeDetectorRef);
  private confirmSvc = inject(ConfirmService);
  private elRef = inject(ElementRef);
  private notificationsSvc = inject(NotificationsService);
  private removeClicklistener: () => void;
  private renderer = inject(Renderer2);

  notifications: any;

  ngOnInit() {
    this.syncNotifications();
  }

  ngAfterViewInit() {
    this.removeClicklistener = this.renderer.listen('document', 'click', (event: PointerEvent) => {
      if (!this.elRef.nativeElement.contains(event.target)) {
        this.notificationsSvc.showNotifications.set(false);
      }
    });
  }

  ngOnDestroy() {
    this.removeClicklistener();
  }

  async deleteAll(): Promise<void> {
    const confirmed = await lastValueFrom(this.confirmSvc.confirm('delete all notifications', 'Delete All Notifications'));

    if (!!confirmed) {
      await this.notificationsSvc.deleteAllNotifications();
      await this.syncNotifications();
    }
  }

  async markAllRead(): Promise<void> {
    await this.notificationsSvc.markAllNotificationsRead();
    await this.syncNotifications();
  }

  async syncNotifications(): Promise<void> {
    const [ notifications ] = await Promise.all([ this.notificationsSvc.getNotifications(), await this.notificationsSvc.updateReadCount() ]);

    this.notifications = groupBy(orderBy(notifications, 'sentDate', 'desc'), ({ seenDate }) => seenDate ? 'read' : 'unread');

    this.cdr.markForCheck();
  }

  close() {
    this.notificationsSvc.showNotifications.set(false);
  }
}
