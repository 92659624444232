import {
  HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest 
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import {
  catchError, filter, skipUntil, switchMap, take 
} from 'rxjs/operators';
import { AuthService } from '~auth/services/auth.service';
import { AuthState } from '~auth/states/auth.state';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(
    private toastr: ToastrService,
    private authSvc: AuthService,
    private authState: AuthState,
    private router: Router
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(req)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (req.url.includes('login') || req.url.includes('isValidPassword')) {
            return throwError(error);
          }

          if (error.status === 401) {
            return this.handle401Error(req, next);
          } else if (error.status === 422) {
            return next.handle(req);
          } else {
            console.error(`ERROR: `, JSON.stringify(error, null, 2));

            let errorMsg = error?.error ?? error.message;

            if (error?.error?.message) {
              errorMsg = error.error.message;
            }

            this.toastr.error(errorMsg, `Error code: ${error?.status}`, {
              closeButton: true
            });

            return throwError(error);
          }
        })
      );
  }

  private async handle401Error(req: HttpRequest<any>, next: HttpHandler) {
    console.debug('auth Token is refreshing', this.authState.get('tokenRefreshing'));

    if (req.url.toLowerCase().includes('tokenrefresh')) {
      this.authState.set('tokenRefreshing', false);
      console.debug('User refresh token has expired!');
      return this.authSvc.logout()
        .pipe(
          switchMap(() => next.handle(req))
        );
    } else if (this.authState.get('tokenRefreshing')) {
      console.debug('token refresh already in progress... waiting...');

      return this.authState.tokenRefreshing$
        .pipe(
          skipUntil(this.authState.tokenRefreshing$.pipe(filter(refreshing => !refreshing))),
          take(1),
          switchMap(() => next.handle(req))
        );
    } else if (this.mismatchedTokens(req)) {
      console.debug('Req Token & User Token do not match!');

      return next.handle(req.clone({
        setHeaders: {
          Authorization: `Bearer ${this.authState.get('token')}`
        }
      }));
    } else if (this.authState.get('refreshToken') && !this.authState.get('tokenRefreshing')) {
      console.debug('User token needs refreshing!');

      const token = await this.authSvc.refreshToken(this.authState.get('refreshToken'));
      return next.handle(req);
    } else {
      console.debug('401 else block');
      return next.handle(req);
    }
  }

  private mismatchedTokens(req: HttpRequest<any>): boolean {
    return this.authState.get('token') !== (req.headers.get('Authorization').split(' ')[1]);
  }
}
