<div class="flex flex-col w-full h-full">
  @if (showTopbar$ | async) {
    <app-topbar></app-topbar>
  }

  <div class="flex flex-row h-full w-full">
    <app-sidebar
      [@slideInOut]="collapsedState$ | async"
      [visibility]="collapsedState$ | async"
      [isMobile]="isMobile"
    ></app-sidebar>

    <div class="stil-outlet flex flex-col grow">
      <router-outlet></router-outlet>
    </div>

    @if (!collapseRightNav) {
      <app-right-nav class="w-[40px]"></app-right-nav>
    }

    @if (showNotifications()) {
      <app-notifications-panel @showHideHorizontal></app-notifications-panel>
    }
  </div>
</div>
