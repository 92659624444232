import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import {
  Observable, forkJoin, switchMap
} from 'rxjs';
import { AuthState } from '~auth/states/auth.state';
import { AppService } from '~core/services/app/app.service';
import { FacilitySettingService } from '~core/services/facility-setting/facility-setting.service';
import { SafeTKDB } from '~core/services/indexeddb/indexeddb.service';
import { SidebarService } from '~core/services/sidebar.service';
import { FacilityState } from '~core/states/facility/facility.state';
import { SidebarState } from '~core/states/sidebar/sidebar.state';
import { PermissionsService } from '~permissions/services/permissions/permissions.service';
import { PermissionsState } from '~permissions/state/permissions.state';
import { TranslationService } from '~translations/services';
import { TranslationsState } from '~translations/state/translations.state';

@Injectable({
  providedIn: 'root'
})
export class AppResolve  {


  constructor(
    private appSvc: AppService,
    private authState: AuthState,
    private facilitySettingSvc: FacilitySettingService,
    private facilityState: FacilityState,
    private indexedDB: SafeTKDB,
    private permissionsSvc: PermissionsService,
    private permissionsState: PermissionsState,
    private router: Router,
    private sidebarSvc: SidebarService,
    private sidebarState: SidebarState,
    private translationSvc: TranslationService,
    private translationState: TranslationsState,
    private injector: Injector
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<boolean> {
    if (this.authState.get('isLoggedIn')) {
      return this.appSvc.initAppState()
        .pipe(
          switchMap(() => this.indexedDB.setConnectionDB()),
          switchMap(() => forkJoin([
            this.facilityState.loadCachedData(),
            this.permissionsState.loadCachedData(),
            this.sidebarState.loadCachedData(),
            this.translationState.loadCachedData()
          ])),
          switchMap(() => forkJoin([
            this.facilitySettingSvc.initFacilityState(),
            this.permissionsSvc.initPermissions(),
            this.sidebarSvc.initSidebarState(),
            this.translationSvc.initTranslationsStateAsync()
          ]))
        );
    } else {
      return this.router.navigateByUrl('/login');
    }
  }
}
